import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormFeedback,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CSpinner,
  CToast,
  CToastBody,
  CToaster,
  CToastHeader,
} from '@coreui/react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import CIcon from '@coreui/icons-react'
import { cibMailRu } from '@coreui/icons'
import getCSRFToken from 'src/utils/getCSRFToken'
import axios from 'axios'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

const PasswordForgotForm = (props) => {
  const { history } = props

  const [error, setError] = useState('')
  const validationSchema = yup.object({
    email: yup.string().email('Invalid email address').required('Email address is required'),
  })

  const { isValid, errors, values, touched, isSubmitting, handleBlur, handleSubmit, handleChange } =
    useFormik({
      initialValues: {
        email: '',
      },
      validationSchema,
      onSubmit: (values, formikHelper) => {
        getCSRFToken().then((resp) => {
          axios
            .post(`${process.env.REACT_APP_API_BASE_URL}/api/password/forgot`, {
              email: values.email,
            })
            .then((resp) => {
              console.log(resp)
              toast.success('password reset link sent')
              formikHelper.setSubmitting(false)
            })
            .catch((e) => {
              // formikHelper.setSubmitting(false)
              formikHelper.setSubmitting(false)
              console.error(e)
              const errorMsg =
                e?.response?.data?.errors?.message?.[0] ??
                'Unable to send password reset link, please try again'
              // toast.error(errorMsg)
              // show server side errors
              let statusCodeErr = [422, 429]
              if (statusCodeErr.find((code) => code === e.response.status)) {
                // alert the error message
                const { errors } = e.response.data
                for (let e in errors) {
                  // Use CoreUi notif alert
                  setError(errors[e][0])
                }
              }
            })
        })
      },
    })

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={6}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm
                    validated={!isValid}
                    onSubmit={(e) => handleSubmit(e)}
                    className="needs-validation"
                    noValidate
                  >
                    <h1>Admin Password Reset</h1>
                    <p className="text-medium-emphasis">
                      Enter your email below to reset your password
                    </p>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cibMailRu} />
                      </CInputGroupText>
                      <CFormInput
                        placeholder="Email Address"
                        autoComplete="Email Address"
                        type="email"
                        id="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                      />

                      <CFormFeedback
                        className="mb-5"
                        tooltip
                        invalid={!!errors.email}
                        valid={!errors.email}
                      >
                        {errors && errors.email
                          ? errors.email
                          : errors && !errors.email && !isValid
                          ? 'Looks Good'
                          : ''}
                      </CFormFeedback>
                    </CInputGroup>

                    <CRow>
                      <CCol xs={6}>
                        <CButton type="submit" color="primary" className="px-4">
                          {isSubmitting && (
                            <CSpinner component="span" size="sm" aria-hidden="true" />
                          )}
                          Send password reset link
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
      {error && (
        <CToaster placement="top-end">
          <CToast color="danger" visible title="Server Error" onClose={() => setError('')}>
            <CToastHeader close="true">
              {/* <svg
                className="rounded me-2"
                width="20"
                height="20"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="xMidYMid slice"
                focusable="false"
                role="img"
              >
                <rect width="100%" height="100%" fill="#007aff"></rect>
              </svg> */}
              <strong className="me-auto">Server Error</strong>
              {/* <small>7 min ago</small> */}
            </CToastHeader>
            <CToastBody style={{ color: 'white' }}>{error}</CToastBody>
          </CToast>
        </CToaster>
      )}
    </div>
  )
}

PasswordForgotForm.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}

export default PasswordForgotForm
