import axios from 'axios'
import React, { Component, useEffect } from 'react'
import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom'
import { SWRConfig } from 'swr'
import "react-datepicker/dist/react-datepicker.css";
import './scss/style.scss'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import PasswordResetForm from './views/pages/password-forgot/PasswordForgot'
import PasswordForgotForm from './views/pages/password-forgot/PasswordForgot'
import './App.css'
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const LandingPage = React.lazy(() => import('./views/pages/home'))

// Browser History
// const history = createBrowserHistory()

export default function App() {
  return (
    <BrowserRouter>
      <React.Suspense fallback={loading}>
        <ToastContainer />
        <SWRConfig
          value={{
            fetcher: (url) => axios(url).then((resp) => resp.data),
          }}
        >
          <Switch>
            <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
            <Route path="/admin" render={(props) => <Login {...props} />} exact />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/password-forgot"
              name="Password forgot"
              render={(props) => <PasswordForgotForm {...props} />}
            />
            <Route
              exact
              path="/password-reset"
              name="Password Reset"
              render={(props) => <PasswordResetForm {...props} />}
            />
            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
            {/* <Route path="/" name="Home" render={(props) => <LandingPage {...props} />} exact /> */}
            <Route path="/" name="Dashboard" render={(props) => <DefaultLayout {...props} />} />
          </Switch>
        </SWRConfig>
      </React.Suspense>
    </BrowserRouter>
  )
}
