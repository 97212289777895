import axios from 'axios'
async function getCSRFToken() {
  try {
    axios.defaults.withCredentials = true
    const response = await axios.get(process.env.REACT_APP_API_BASE_URL + '/sanctum/csrf-cookie')

    if (response.status === 200) return response.data
  } catch (error) {
    return error
  }
}

export { getCSRFToken as default }
